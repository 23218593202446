<template>
  <v-dialog :value="cartModal" fullscreen persistent>
    <v-card>
      <v-card-title class="d-flex justify-center">Mijn bestelling</v-card-title>
      <v-row justify="center">
        <v-col>
          <div class="d-flex justify-space-between">
            <date-picker
              :value="deliveryDate"
              label="Leverdatum"
              :dateFormatted="deliveryDateFormatted"
              @changeDate="changeDeliveryDate"
            ></date-picker>
            <div class="day-of-week align-self-end">
              {{ days[date.getDay()] }}
            </div>
          </div>
          <v-list outlined>
            <v-list-item v-for="(item, index) in cart" :key="index">
              <v-list-item-content>{{ item.name }}</v-list-item-content>
              <v-list-item-content>
                <div>
                  <input
                    class="item-amount"
                    type="number"
                    :value="item.amount"
                    @change="updateOrderItem"
                  />
                  {{ item.unit }}
                </div>
              </v-list-item-content>
              <v-list-item-action
                ><v-icon @click="deleteOrderItem(item)"
                  >mdi-delete</v-icon
                ></v-list-item-action
              >
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-footer fixed class="d-flex justify-center">
          <v-btn-toggle>
            <v-btn color="primary" @click="closeCartModal">Annuleer</v-btn>
            <v-btn color="primary" @click="insertOrder">Bevestigen</v-btn>
          </v-btn-toggle>
        </v-footer></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { toDateString, toCurrency, toTimeString } from "../js/functions"
import datePicker from "@/components/datePicker.vue"
export default {
  name: "cartModal",
  props: {
    cartModal: Boolean,
    cart: Array
  },
  data() {
    return {
      currentTime: toTimeString(new Date()),
      deliveryDate: new Date().toISOString(),
      date: new Date(),
      deliveryDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      days: [
        "Zondag",
        "Maandag",
        "Dinsdag",
        "Woensdag",
        "Donderdag",
        "Vrijdag",
        "Zaterdag"
      ],
      months: [
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maart", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Augustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 }
      ]
    }
  },
  methods: {
    toDateString(date) {
      return toDateString(date)
    },
    toCurrency(number) {
      return toCurrency(number)
    },
    closeCartModal() {
      this.$emit("close-cart-modal")
    },
    updateOrderItem(event) {
      console.log(event.target.value)
    },
    deleteOrderItem(item) {
      this.$emit("delete-order-item", item)
    },
    insertOrder() {
      this.$emit("insert-order")
      this.closeCartModal()
    },
    changeDeliveryDate(date) {
      let newDate = new Date(date)
      this.deliveryDate = newDate.toISOString().split("T")[0]
      this.deliveryDateFormatted = this.formatDate(this.deliveryDate)
      this.date = newDate
      this.$emit("change-delivery-date", newDate)
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    }
  },
  mounted() {
    let deliveryDate = new Date(this.deliveryDate)
    if (this.currentTime > "18:00") {
      let newDate = new Date(deliveryDate.setDate(deliveryDate.getDate() + 1))
      this.changeDeliveryDate(newDate)
    }
  },
  components: {
    datePicker
  }
}
</script>

<style scoped>
.item-amount {
  width: 60px;
  text-align: right;
}
.day-of-week {
  width: 50%;
  font-weight: bold;
}
</style>